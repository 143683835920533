
/* print style */

iframe {
    border: none;
    display: block;
    min-width: 100%;
    height: 550px
  }

  #printMod .modal-body {
    overflow: auto
  }

  #plwrapper {
    margin: 0 auto;
    background: none;
    border: 2px solid #000
  }

  #plcontainer {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: left
  }

  #plmapcontrols {
    position: absolute;
    margin: 2px
  }

  .plwrapper_p {
    width: 970px !important;
    height: 1380px !important
  }

  .pllegwrapper_p {
    width: 970px !important;
    height: 1380px !important
  }

  .plwrapper_l {
    width: 1380px;
    height: 910px
  }

  .pllegwrapper_l {
    width: 1380px;
    height: 910px
  }

  .plmapcontrols_p {
    position: absolute;
    top: 1202px;
    width: 100%;
    height: 14%
  }

  .plmapcontrols_l {
    top: 0px;
    left: 0px;
    width: 20%;
    height: 100%
  }

  .map_p {
    position: absolute;
    top: 0;
    height: 1202px;
    width: 100%;
    border-bottom: 1px solid #000
  }

  .map_l {
    position: absolute;
    top: 0;
    right: 0;
    width: 1102px;
    height: 100%;
    border-left: 1px solid #000
  }

  #northdiv {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1001;
    width: 55px;
    background: #fff;
    filter: alpha(opacity=85);
    opacity: .75;
    -webkit-box-shadow: 0 0 0 3px rgba(134, 142, 150, .5);
    box-shadow: 0 0 0 3px rgba(134, 142, 150, .5);
    border-radius: .25rem
  }

  .printlimg {
    width: 100%
  }

  .copytext_l {
    position: absolute !important;
    bottom: 2px !important;
    z-index: 1000 !important;
    margin-left: 6px !important
  }

  .copytext_p {
    position: absolute !important;
    bottom: 2px !important;
    z-index: 1000 !important;
    margin-left: 6px !important
  }

  #print_scale_line {
    position: absolute;
    z-index: 1000;
    bottom: 12px;
    left: -2px
  }

  #print_scale_line .ol-scale-line {
    background: #fff;
    padding: 3px
  }

  #print_scale_line .ol-scale-line-inner {
    background: #fff;
    color: #000 !important;
    border: 2px solid #000;
    border-top: none
  }

  #datenotesdiv {
    font-size: .7em
  }

  .datenotesdiv_p {
    width: 30%;
    position: absolute;
    left: 0px;
    height: 100%
  }

  .datenotesdiv_l {
    width: 95%;
    margin: 1px;
    height: 30%
  }

  // input[type=checkbox] {
  //   display: none
  // }

  #ptitlediv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    margin: 1px;
    margin-top: 0px
  }

  #ptitletext {
    display: block;
    width: 100%;
    height: 26px;
    font-weight: bold;
    font-size: 1rem;
    overflow: hidden !important
  }

  #datediv {
    position: absolute;
    top: 26px;
    width: 99%;
    margin: 1px;
    height: 10%
  }

  #notesdiv {
    position: absolute;
    top: 46px;
    width: 99%;
    height: 118px;
    margin-top: 4px
  }

  #pdatetext {
    font-size: 1rem
  }

  #pnotes {
    font-size: .9rem;
    width: 100%;
    height: 100%
  }

  .addtext {
    resize: none;
    border-style: none
  }

  #logodiv {
    background: none
  }

  .logodiv_p {
    height: 100%;
    width: 30%;
    right: 0px;
    margin-right: 5px;
    position: absolute;
    padding-top: 3%
  }

  .logodiv_l {
    height: 19%;
    width: 95%;
    margin: 1px;
    padding-top: 10%
  }

  #logocontainer {
    width: 80%;
    margin: 0px auto;
    position: relative
  }

  .logocontainer_p {
    height: 58%
  }

  .logocontainer_l {
    height: 65%
  }

  .twdiv_p {
    width: 97%
  }

  .twdiv_l {
    width: 99%
  }

  #twtext {
    text-align: center
  }

  .dataFilter {
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 3px
  }

  #orderdisplay {
    overflow: hidden
  }

  .orderdisplay_p {
    position: absolute;
    height: 100%;
    width: 37%;
    left: 31%
  }

  .orderdisplay_l {
    height: 50%;
    width: 95%;
    margin: 1px
  }

  .legendlist {
    padding: 2px
  }

  .leglist {
    display: block;
    min-height: 26px
  }

  .marker {
    z-index: 1000;
    position: absolute;
    left: 5px;
    width: 210px;
    border-radius: .25rem;
    -webkit-box-shadow: 0 0 0 3px rgba(134, 142, 150, .5);
    box-shadow: 0 0 0 3px rgba(134, 142, 150, .5);
    background-color: #fff;
    overflow: hidden;
    cursor: move
  }

  .marker span {
    display: block;
    margin: 5px;
    margin-left: 0;
    max-width: 160px;
    float: right;
    word-wrap: break-word
  }

  .markerIcon {
    background-repeat: no-repeat;
    float: left;
    background-color: #fff;
    width: 32px;
    height: 40px;
    margin: 8px 5px
  }

  #pllegwrapper {
    position: relative;
    margin: 0 auto;
    border: 2px solid #000;
    display: none;
    page-break-before: always
  }

  .pllegwrapper_p h2,
  .pllegwrapper_l h2 {
    margin: 2px 3px;
    margin-left: 1%
  }

  #orderdisplaycol1,
  #orderdisplaycol2,
  #orderdisplaycol3 {
    position: absolute;
    top: 50px;
    height: 100%;
    margin: 1px;
    overflow: hidden
  }

  #orderdisplaycol1 {
    left: 1%;
    width: 32%
  }

  #orderdisplaycol2 {
    right: 33%;
    width: 32%
  }

  #orderdisplaycol3 {
    right: 0;
    width: 32%
  }

  #printBody {
    background: #fff;
    border: none !important
  }

  @media print {
    @page {
    size: auto;
}
body {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    width: 100% !important;
}
body * {
    visibility: hidden;
}
#printMod, #printMod * {
    visibility: visible;
    display: block;
    flex: none !important;
}
#printBody {
    display: table !important;
    margin: auto !important;
}
#printMod {
    display: table !important;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    break-inside: auto;
    border: none !important;
    overflow: visible !important;
}
#printMod .modal-header, #printMod .modal-footer {
    display: none;
    border: none !important;
}
#printMod .modal-dialog, #printMod .modal-content, #printMod .modal-body {
    display: block;
    overflow: visible;
    height: 100% !important;
    max-height: 100% !important;
    padding: 0;
    border: none !important;
}
#printMod #plwrapper, #printMod #pllegwrapper {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
}
#printMod #pagesplit {
    display: block;
    page-break-before: always !important;
    break-before: page !important;
}
}/*# sourceMappingURL=twbootstrap.css.map */
