/* general */
.client-logo {
  height: 70px;
  padding: 0;
}

.hide {
  display: none;
}

.slidesection {
  width: 100%;
}

button:disabled,
button[disabled] {
  background: grey;
}

ul {
  list-style: none;
}

.aboutul {
    padding: 0;
    padding-left: 15px;
    width: 90%;
    margin: 20px auto 0;
    list-style-type: square;
}

.modalimage {
    max-width:100%;
}

.modallink {
    font-weight: bold;
}

.aboutimg {
    max-width:100%;
}

.abouth2 {
    padding-left:15px;
}
