.markauto {
    width: auto;
    height: 32px;
    padding: 0 10px
}

.markauto:before {
    margin-right: 5px
}

#drawtools p {
    margin: 5px auto
  }
  
  
  .maxmarkers,
  .maxfilters {
    display: none;
    color: red
  }
  
  .photoDiv {
    margin-top: 1rem;
  }
 
  .textDiv textarea {
    width: 100%;
    display: none;
    resize: none;
    overflow: hidden;
    border-style: none
  }

  .textDiv {
    width: 100%;
    border: 1px solid silver;
    margin-top: 4px;
  }

  .filter,
  .fmarkers {
    margin-top: 10px
  }
  
  .filter {
    display: none;
    margin-top: 15px
  }
  
  .markerdiv,
  .filter {
    border: 1px solid #868e96;
    border-radius: .25rem;
    padding: 10px
  }
  