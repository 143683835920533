/* ol css */
//IE 11 Fallback and mobile theme
.ol-rotate-reset,
.ol-scale-line {
  background: black;
  color: #fff;
}
//END OF IE 11 FALLBACK

@media (min-width: 768px) {
  .ol-rotate-reset,
  .ol-scale-line {
    background: var(--back-color);
    color: #fff;
  }
}

.ol-scale-line-inner {
  border: 1px solid #fff;
  border-top: 0;
  color: #fff !important;
}
.ol-overviewmap-box {
  border: 2px solid #fc3e3e !important;
}


.ol-zoom-in,
.ol-zoom-out,
.ol-overviewmap button,
.ol-attribution {
    display: none !important
}

.ol-overviewmap-map .ol-viewport {
    background: #fff
}

.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid rgb(8, 8, 8);
}

.ol-scale-line {
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}
.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 0.7rem;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
}
.ol-overlay-container {
  will-change: left, right, top, bottom;
}
.ol-unsupported {
  display: none;
}
.ol-unselectable,
.ol-viewport {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}
.ol-control:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.ol-zoom {
  top: 0.5em;
  left: 0.5em;
}
.ol-rotate {
  top: 0.5em;
  right: 0.5em;
  transition: opacity 0.25s linear, visibility 0s linear;
}
.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}
.ol-zoom-extent {
  top: 4.643em;
  left: 0.5em;
}
.ol-full-screen {
  right: 0.5em;
  top: 0.5em;
}
@media print {
  .ol-control {
      display: none;
  }
}
.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: #fff;
  font-size: 1.14em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: 0.4em;
  background-color: rgba(0, 60, 136, 0.5);
  border: none;
  border-radius: 2px;
}
.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}
.ol-zoom-extent button {
  line-height: 1.4em;
}
.ol-compass {
  display: block;
  font-weight: 400;
  font-size: 1.2em;
  will-change: transform;
}
.ol-touch .ol-control button {
  font-size: 1.5em;
}
.ol-touch .ol-zoom-extent {
  top: 5.5em;
}
.ol-control button:focus,
.ol-control button:hover {
  text-decoration: none;
  background-color: rgba(0, 60, 136, 0.7);
}
.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}
.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}
.ol-attribution {
  text-align: right;
  bottom: 0.5em;
  right: 0.5em;
  max-width: calc(100% - 1.3em);
}
.ol-attribution ul {
  margin: 0;
  padding: 0 0.5em;
  font-size: 0.7rem;
  line-height: 1.375em;
  color: #000;
  text-shadow: 0 0 2px #fff;
}
.ol-attribution li {
  display: inline;
  list-style: none;
  line-height: inherit;
}
.ol-attribution li:not(:last-child):after {
  content: " ";
}
.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}
.ol-attribution button,
.ol-attribution ul {
  display: inline-block;
}
.ol-attribution.ol-collapsed ul {
  display: none;
}
.ol-attribution.ol-logo-only ul {
  display: block;
}
.ol-attribution:not(.ol-collapsed) {
  background: rgba(255, 255, 255, 0.8);
}
.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
  height: 1.1em;
  line-height: 1em;
}
.ol-attribution.ol-logo-only {
  background: 0 0;
  bottom: 0.4em;
  height: 1.1em;
  line-height: 1em;
}
.ol-attribution.ol-uncollapsible img {
  margin-top: -0.2em;
  max-height: 1.6em;
}
.ol-attribution.ol-logo-only button,
.ol-attribution.ol-uncollapsible button {
  display: none;
}
.ol-zoomslider {
  top: 4.5em;
  left: 0.5em;
  height: 200px;
}
.ol-zoomslider button {
  position: relative;
  height: 10px;
}
.ol-touch .ol-zoomslider {
  top: 5.5em;
}
.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}
.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}
.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block;
}
.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px;
}
.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 1px;
  left: 2px;
  position: absolute;
}
.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}
.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255, 255, 255, 0.8);
}
.ol-overviewmap-box {
  border: 2px dotted rgba(0, 60, 136, 0.7);
}
.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

/* map extras */

#map_extras {
  background: #fff;
  position: absolute;
  bottom: 4px;
  z-index: 100;
  right: 4px;
  width: 370px;
  border-radius: .25rem;
  box-shadow: 0 0 0 3px rgba(134, 142, 150, .5);
  height: 45px;
  padding: 10px;
  padding-bottom: 0;
  text-align: left
}

.ol-scale-line {
  display: block;
  bottom: 10px;
  left: 12px
}

#mapcoords {
  margin-top: 2px;
  margin-left: 110px;
  float: left;
  font-size: smaller;
}

@media(min-width: 318px) {
  .copytext {
      position: absolute;
      z-index: 10;
      margin: 0;
      bottom: 0;
      background: rgba(255, 255, 255, .6);
      border-radius: .5em;
      font-size: 0.7rem;
      padding: 2px
  }
}

@media(min-width: 768px) {
  .copytext {
      max-width: 350px
  }
}

@media(min-width: 992px) {
  .copytext {
      max-width: 550px
  }
}

#showovmap {
  width: 44px;
  padding-left: 11px
}

.ol-overviewmap {
  bottom: .7em
}

.ol-mouse-position {
  position: relative;
  top: 0;
  right: 0;
  display: inline-block
}

.ovbuttondiv {
  display: block;
  position: relative;
  bottom: 2px;
  text-align: right
}

#ovmap {
  display: inline-block;
  position: relative;
  right: 160px;
  bottom: 15px
}

.ol-viewport {
  border: 1px solid #868e96
}
