// COVERS ALL CONTROLS IN THE LEGEND AND TOOLBAR CONTROL

//COLOUR AND THEMES

//IE 11 Fallback to generic theme
.tlruler,
.custbtn,
.maptoolbtn,
.formbtn,
.groupbtn {
    color:white;
    background-color: black;
    border: 1px solid white;
}

.btn,
.custbtn,
.maptoolbtn,
.legendbtn,
.formbtn,
.groupbtn,
.tlruler,
.tlruler:hover {
    background: black;
    color: white;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black;
}

.btn:focus,
.btn:hover,
.custbtn:focus,
.custbtn:hover,
.formbtn:focus,
.formbtn:hover,
.legendbtn:focus,
.legendbtn:hover,
.custbtnsel,
.maptoolbtn:focus,
.maptoolbtn:hover,
.groupbtn:focus,
.groupbtn:hover,
.tlruler:hover {
    background: #484848;
    color: white;
}

.searchcont {
    color: #484848;
}

.tlruler:hover .rulerSvg, .custbtnsel,
.locposbutton:hover .posiconSvg {
    fill: white;
    stroke: white;
}

#feature_list .fa-close:before,
#feature_list .fa-remove:before,
#feature_list .fa-times:before,
#feature_list .fa:before {
    background: black;
    color:white;
}
#feature_list .group_btn:hover em::before,
#feature_list .group_btn:hover::before,
#feature_list li:hover,
#feature_list li:hover em::before {
    color: white;
}
#feature_list li:hover em::before {
    background: #484848;
}

.pdq_default {
    background: black;
    color: white;
}
.pdq_on {
    background: #484848;
    color: white;
}
.check,
.checkmark,
.container input:checked ~ .check,
.container input:checked ~ .checkmark {
    background-color: black;
}
.container .checkmark::after {
    background: black;
}

.container:hover input ~ .check,
.container:hover input ~ .checkmark {
    background-color: #484848;
}
.container:hover input ~ .check::after {
    border-color:white;
}
.container:hover input ~ .checkmark::after {
    background: #484848;
}
footer {
    background: black;
    color: white;
}

.tlruler .rulerSvg,
.locposbutton .posiconSvg {
    fill: white;
    stroke: white;
}

.card-header {
  background-color: black !important;
  color: white !important;
}

.custom-control-input:focus~.custom-control-label::before {
  border-color: white!important;
  box-shadow: 0 0 0 0.2rem rgba(175, 177, 175, 0.25) !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: white !important;
  background-color: black !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: white !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: #d6f5dd !important;
  border-color: #d6f5dd !important;
}

.btn-secondary {
  border-color: white;
}
//END OF IE11 Fallback

@media (min-width: 768px) {

  .tlruler,
  .custbtn,
  .maptoolbtn,
  .formbtn,
  .groupbtn {
      color: var(--font-color);
      background-color: var(--back-color);
      border: 1px solid var(--border-color);
  }

  .btn,
  .custbtn,
  .maptoolbtn,
  .legendbtn,
  .formbtn,
  .groupbtn,
  .tlruler,
  .tlruler:hover {
      background: var(--back-color);
      color: var(--font-color);
  }

  .btn-secondary {
    border-color: var(--font-color);
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: var(--font-color);
    background-color: var(--back-color);
    border-color: var(--font-color);
    }
  .btn:focus,
  .btn:hover,
  .custbtn:focus,
  .custbtn:hover,
  .formbtn:focus,
  .formbtn:hover,
  .legendbtn:focus,
  .legendbtn:hover,
  .custbtnsel,
  .maptoolbtn:focus,
  .maptoolbtn:hover,
  .groupbtn:focus,
  .groupbtn:hover,
  .tlruler:hover {
      background: var(--back-highlight);
      color: var(--font-highlight);
  }

  .searchcont {
      color: var(--back-highlight);
  }

  .tlruler:hover .rulerSvg, .custbtnsel,
  .locposbutton:hover .posiconSvg {
      fill: var(--font-highlight);
      stroke: var(--font-highlight);
  }

  #feature_list .fa-close:before,
  #feature_list .fa-remove:before,
  #feature_list .fa-times:before,
  #feature_list .fa:before {
      background: var(--back-color);
      color:var(--font-color);
  }
  #feature_list .group_btn:hover em::before,
  #feature_list .group_btn:hover::before,
  #feature_list li:hover,
  #feature_list li:hover em::before {
      color: var(--font-highlight);
  }
  #feature_list li:hover em::before {
      background: var(--back-highlight);
  }

  .pdq_default {
      background: var(--back-color);
      color: var(--font-color);
  }
  .pdq_on {
      background: var(--back-highlight);
      color: var(--font-highlight);
  }
  .check,
  .checkmark,
  .container input:checked ~ .check,
  .container input:checked ~ .checkmark {
      background-color: var(--back-color);
  }
  .container .checkmark::after {
      background: var(--back-color);
  }

  .container:hover input ~ .check,
  .container:hover input ~ .checkmark {
      background-color: var(--back-highlight);
  }
  .container:hover input ~ .check::after {
      border-color: var(--border-color);
  }
  .container:hover input ~ .checkmark::after {
      background: var(--back-highlight);
  }
  footer {
      background: var(--footer-color);
      color: var(--font-color);
  }

  .tlruler .rulerSvg,
  .locposbutton .posiconSvg {
      fill: var(--font-color);
      stroke: var(--font-color);
  }

  .card-header {
    background-color: var(--back-color) !important;
    color: var(--font-color) !important;
  }

  .custom-control-input:focus~.custom-control-label::before {
    border-color: var(--border-color) !important;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
  }

  .custom-control-input:checked~.custom-control-label::before {
    border-color: var(--border-color) !important;
    background-color: var(--back-color) !important;
  }

  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: var(--border-color) !important;
  }

  .custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: #d6f5dd !important;
    border-color: #d6f5dd !important;
  }
}
// END OF COLOUR AND THEME SETTINGS

.maxbtn {
  width: 100%;
}
.altbtn, .altbtn:hover,.altbtn:focus {
    background: silver;
    color: black;
    border: 1px solid silver;
    border-bottom: 2px solid silver;
    box-shadow: none;
}

.tlruler,
.custbtn,
.maptoolbtn,
.formbtn,
.groupbtn {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    padding: 0;
    border-radius: .25rem;
    transition: all .15s ease-in-out;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25
}


.groupbtn,
.formbtn {
  width: auto;
  padding: 0 5px
}

.legendbtn {
  width: 100%;
  text-align: left;
}
.legendbtn:focus,
.legendbtn:hover,
.legendbtn:active {
  outline: none;
  box-shadow: none;
}

.maptoolbtn {
  width:100%;
  padding:0px;
}

.tlrulerbox,
.themesopt,
.printopt {
    margin-right: 5px
}

.printopt,
.tlrulerbox,
.reportopt {
    display: inline;
    margin-top: 3px
}

.themesopt {
  display: block;
  background-color: white;
  color: black;
  border-radius: 0.25rem;
  padding: 5px;
}
.printopt,
.themesopt,
.tlrulerbox,
.reportopt {
    margin-top: 3px
}

.tlruler .rulerSvg,
.locposbutton .posiconSvg {
    stroke-width: 10px;
    margin-top: 2px;
}

.container .check::after {
    border: solid #fff;
    border-width: 0 3px 3px 0;
}

.titleheader {
  display:none;
}
/* map tools css */
#schemes {
  overflow-y: auto
}

.streetname .list-divider {
  margin: 6px 0;
  clear: both
}

.schemeHead h5,
.schemeHead em {
  cursor: pointer
}

.schemeHead h5 {
  font-size: 1.1rem;
  font-weight: bold
}

.schemeHead p {
  font-weight: 500;
  margin-bottom: 2px
}

.streetname {
  padding-left: 20px
}

.streetname li a {
  color: #000;
  cursor: pointer
}

.streetname li a:hover {
  text-decoration: underline !important
}

#searchClose {
  cursor: pointer;
}

.frahalf {
  width: 50%;
  margin: 0;
  float: left
}

#statuses {
  display: inline-block;
  width: 100%
}

#frasearchcont,
#psasearchcont {
  max-height: 160px
}

#searchcont,
#frasearchcont,
#psasearchcont {
  margin-top: 20px;
  overflow-y: scroll
}

#search_results,
#frasearch_results,
#psasearch_results {
  padding-left: 10px
}

#search_results li,
#frasearch_results li,
#psasearch_results li {
  cursor: pointer;
  margin: 3px auto
}

.input-group-text {
  padding: 10px 12px
}

#input-group-addon {
  display: block;
  padding: 7px 5px;
}

.activedatelabel {
  display: block;
  padding-top: 7px;
  padding-right: 5px;
}

#legendBody {
  overflow-y: scroll;
}

.tool-body {
    overflow-y: auto;
}

#switchdisplay {
  display: block;
  padding-bottom: 10px;
  border-top: 1px dotted #dee2e6;
}

#switchdisplay button {
  padding-right: 8px
}

#feature_list ul {
  padding-left: 0;
  min-width: 275px;
  max-width: 450px;
  width: 100%;
  margin-bottom: 0
}

#feature_list li {
  display: inline-block;
  width: 100%
}

#feature_list em {
  width: 20px;
  height: 20px;
  float: left;
  margin-top: 4px;
  margin-right: 8px;
  cursor: pointer
}

@media(min-width: 318px) {
  #feature_list label {
      display: inline-block;
      width: 75%
  }
}

@media(min-width: 400px) {
  #feature_list label {
      width: 80%
  }
}


#feature_list .pcLegend img {
  margin-top: -1px
}

#feature_list p {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif
}

.pcLegend {
  padding-left: 0;
  min-width: 275px;
  width: 100%;
  margin-bottom: 0
}

@media(min-width: 318px) {
  .pcLegend li p {
      display: inline-block;
      width: 85%;
      margin-bottom: 7px
  }
  #consult_list li:hover {
    color:#000;
  }
}

@media(min-width: 400px) {
  .pcLegend li p {
      width: 90%
  }
}

.pcLegend img {
  float: right;
  margin-top: 3px
}

#select_layers {
  width: 100%;
  margin-bottom: 10px
}

.dRange {
  font-weight: bold
}

.legImg {
  margin: 2px;
  width: 35px;
  height: 24px;
  float: right
}

#pdqlist {
  padding-left: 0;
  text-align: center
}

.CollType {
  font-weight: bold;
  margin-bottom: 10px
}

.pdq_default {
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  margin-bottom: 2px;
  padding: 2px 0;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.pdq_on {
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.invlegImg {
  margin: 2px;
  width: 20px;
  height: 20px;
  float: right
}

#feature_list  label {
  margin-bottom: 0.2rem;
}

#displayTools {
  padding-bottom: 10px;
}


.tlruler .rulerSvg, .locposbutton .posiconSvg {
	fill: white;
	stroke-width: 10px;
	stroke: white;
	margin-top: 2px;
}

.card-header {
  padding: 0.25rem;
}

#todate, #activedate {
  margin-right: 2px;
}

#marksub {
  width:100%;
}

.grouplist {
  border-top: 1px dotted #dee2e6;
}


.card-body {
  padding: 0.5rem;
}
