/****************
DASHBOARD
****************/

.dashboardselecter {
    & {
      position: fixed;
      left: 0;
      height: 90%;
      z-index: 13;
      background: #f5f5f5;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, .07);
    }
    ul {
      max-height: 75%;
      overflow-y: scroll;
      padding: 20px 20px 0px 20px;
      margin: 0;
    }
  }

  .dashboardselecter a {
    & {
      display: inline-block;
      vertical-align: baseline;
      font-size: 1rem;
      background: 0 0;
      text-decoration: none !important;
      // color: white !important;
    }
    &::before {
      content: '';
      display: block;
      width: 0;
      height: .15rem;
      background: black;
      position: relative;
      left: 0;
      bottom: -1.5rem;
      -webkit-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
    }
    &:hover::before,
    &:focus::before {
      width: 100%;
      text-decoration: none !important;
    }
  }

  .dashboardselecter .dashselected {
    &::before {
      width: 100%;
      text-decoration: none !important;
    }
  }

    .dashboardselecter .dashselected a {
    &::before {
      background: white !important;
    }
  }

  .dashboardselect-btn {
    & {
      position: absolute;
      top: 0;
      z-index: 4;
      background: #f5f5f5 !important;
      min-width: 30px;
      padding: 5px 0;
      font-size: 1.5rem;
    }
    .fa {
      margin-top: 2px;
    }
  }

  .dashboardContainer {
    & {
      padding: 30px 0 !important;
      margin: 0 auto !important;
    }
  }

  .container-header {
    & {
      display: flex;
      flex-direction: initial;
      padding: 10px 0 0 40px;
    }
    .container-header-pan-sel {
      & {
        background: #f5f5f5;
        height: 40px;
        border: none;
        padding: 2px 15px;
        font-size: 1.2rem;
        border: 1px solid rgba(0, 0, 0, 0.07);
      }
      &:hover,
      &:focus {
        background: #fff;
      }
    }
    .pan-sel-selected {
      background: #fff;
      border-bottom: none;
    }
    .pan-sel-chart {
      border-radius: .5rem 0 0 0;
    }
    .pan-sel-map {
      border-radius: 0 .5rem 0 0;
      border-left: none;
    }
    .pan-sel-survey {
      border-radius: 0 .5rem 0 0;
      border-left: none;
    }
    .fa::before {
      margin-right: 5px;
    }
    .cont-header-btn-desc {
      & {
        display: inline;
      }
    }
  }

  .panel-container {
    & {
      border-radius: .25rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
    }
  }
  .panel-header {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .panel-header-chart {

    canvas {
      margin: auto !important;
    }
  }

  .panel-header-map {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }



.dashboardselect-btn,
.dashboardselect-btn:hover,
.dashboardselect-btn:focus {
  width: 100%;
  border: none;
  background: #f5f5f5;
  color: black;
}
.dashselected {
background: black;
color: white;
}

.dashselected a {
  color: white !important;
}

.dashfound {
background: silver;
color: black;
}
@media (min-width: 768px) {
    .dashselected {
        background: var(--back-highlight);
        color: var(--font-highlight);
        border-radius: 5px;
        padding: 5px;
      }

      .dashfound {
        background: var(--back-color);
        color: var(--font-color);
      }
      .dashboardselect-btn,
      .dashboardselect-btn:hover,
      .dashboardselect-btn:focus {
        width: 100%;
        border: none;
        background:  var(--back-color) !important;
        color: var(--font-color);
      }
}

#dashsearch {
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  width: 90%;
}

.customchartlegend {
  display: inline-block;
  width: 40px;
  height: 15px;
  margin-right: 5px;
}

.prevnext {
  & {
    display: inline-block;
    width: auto !important;
  }
}

.nextWeek {
  position: absolute;
  right: 16px;
}

.repbtn {
  width:100%;
}

.chartul {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
}

.chartul li {
  margin: 0 3px;
  float: left;
}

.container-title {
  position: absolute;
  right:10px;
}

#dash_accidentdate {
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
}

.dashpara {
    margin-left: 20px;
}

.dash_nopdq {
    display: none !important;
}

.header-accs-sel {
    font-size: 1rem;
}
