/* linestyle */

/****************
v3.3
****************/

img.line1_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 0;}
img.line2_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px 0; background-color:black;-ms-high-contrast-adjust: none;}
img.line3_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px 0;background-color:black;-ms-high-contrast-adjust: none;}
img.line4_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px 0;background-color:black;-ms-high-contrast-adjust: none;}

img.line5_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -20px;background-color:black;-ms-high-contrast-adjust: none;}
img.line6_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -20px;background-color:black;-ms-high-contrast-adjust: none;}
img.line7_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -20px;background-color:black;-ms-high-contrast-adjust: none;}
img.line8_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -20px;background-color:black;-ms-high-contrast-adjust: none;}

img.line9_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -40px;background-color:black;-ms-high-contrast-adjust: none;}
img.line10_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -40px;background-color:black;-ms-high-contrast-adjust: none;}
img.line11_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -40px;background-color:black;-ms-high-contrast-adjust: none;}
img.line12_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -40px;background-color:black;-ms-high-contrast-adjust: none;}

img.line13_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -60px;background-color:black;-ms-high-contrast-adjust: none;}
img.line14_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -60px;background-color:black;-ms-high-contrast-adjust: none;}
img.line15_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -60px;background-color:black;-ms-high-contrast-adjust: none;}
img.line16_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -60px;background-color:black;-ms-high-contrast-adjust: none;}

img.line17_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -80px;background-color:black;-ms-high-contrast-adjust: none;}
img.line18_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -80px;background-color:black;-ms-high-contrast-adjust: none;}
img.line19_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -80px;background-color:black;-ms-high-contrast-adjust: none;}
img.line20_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -80px;background-color:black;-ms-high-contrast-adjust: none;}

img.line21_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -100px;background-color:black;-ms-high-contrast-adjust: none;}
img.line22_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -100px;background-color:black;-ms-high-contrast-adjust: none;}
img.line23_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -100px;background-color:black;-ms-high-contrast-adjust: none;}
img.line24_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -100px;background-color:black;-ms-high-contrast-adjust: none;}

img.line25_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -120px;background-color:black;-ms-high-contrast-adjust: none;}
img.line26_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -120px;background-color:black;-ms-high-contrast-adjust: none;}
img.line27_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -120px;background-color:black;-ms-high-contrast-adjust: none;}
img.line28_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -120px;background-color:black;-ms-high-contrast-adjust: none;}

img.line29_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -140px;background-color:black;-ms-high-contrast-adjust: none;}
img.line30_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -140px;background-color:black;-ms-high-contrast-adjust: none;}
img.line31_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -140px;background-color:black;-ms-high-contrast-adjust: none;}
img.line32_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -140px;background-color:black;-ms-high-contrast-adjust: none;}

img.line33_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -160px;background-color:black;-ms-high-contrast-adjust: none;}
img.line34_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -160px;background-color:black;-ms-high-contrast-adjust: none;}
img.line35_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -160px;background-color:black;-ms-high-contrast-adjust: none;}
img.line36_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -160px;background-color:black;-ms-high-contrast-adjust: none;}

img.line37_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -180px;background-color:black;-ms-high-contrast-adjust: none;}
img.line38_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -180px;background-color:black;-ms-high-contrast-adjust: none;}
img.line39_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -180px;background-color:black;-ms-high-contrast-adjust: none;}
img.line40_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -180px;background-color:black;-ms-high-contrast-adjust: none;}

img.line41_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -200px;background-color:black;-ms-high-contrast-adjust: none;}
img.line42_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -200px;background-color:black;-ms-high-contrast-adjust: none;}
img.line43_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -200px;background-color:black;-ms-high-contrast-adjust: none;}
img.line44_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -200px;background-color:black;-ms-high-contrast-adjust: none;}

img.line45_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -220px;background-color:black;-ms-high-contrast-adjust: none;}
img.line46_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -220px;background-color:black;-ms-high-contrast-adjust: none;}
img.line47_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -220px;background-color:black;-ms-high-contrast-adjust: none;}
img.line48_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -220px;background-color:black;-ms-high-contrast-adjust: none;}

img.line49_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -240px;background-color:black;-ms-high-contrast-adjust: none;}
img.line50_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -240px;background-color:black;-ms-high-contrast-adjust: none;}
img.line51_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -240px;background-color:black;-ms-high-contrast-adjust: none;}
img.line52_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -240px;background-color:black;-ms-high-contrast-adjust: none;}

img.line53_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -260px;background-color:black;-ms-high-contrast-adjust: none;}
img.line54_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -260px;background-color:black;-ms-high-contrast-adjust: none;}
img.line55_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -260px;background-color:black;-ms-high-contrast-adjust: none;}
img.line56_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -260px;background-color:black;-ms-high-contrast-adjust: none;}

img.line57_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -280px;background-color:black;-ms-high-contrast-adjust: none;}
img.line58_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -280px;background-color:black;-ms-high-contrast-adjust: none;}
img.line59_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -280px;background-color:black;-ms-high-contrast-adjust: none;}
img.line60_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -280px;background-color:black;-ms-high-contrast-adjust: none;}

img.line61_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -300px;background-color:black;-ms-high-contrast-adjust: none;}
img.line62_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -300px;background-color:black;-ms-high-contrast-adjust: none;}
img.line63_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -300px;-ms-high-contrast-adjust: none;}
img.line64_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -300px;-ms-high-contrast-adjust: none;}

img.line65_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -320px;-ms-high-contrast-adjust: none;}
img.line66_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -320px;-ms-high-contrast-adjust: none;}
img.line67_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -320px;-ms-high-contrast-adjust: none;}
img.line68_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -320px;-ms-high-contrast-adjust: none;}

img.line69_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -340px;-ms-high-contrast-adjust: none;}
img.line70_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -340px;-ms-high-contrast-adjust: none;}
img.line71_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -340px;-ms-high-contrast-adjust: none;}
img.line72_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -340px;-ms-high-contrast-adjust: none;}

img.line73_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -360px;-ms-high-contrast-adjust: none;}
img.line74_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -360px;-ms-high-contrast-adjust: none;}
img.line75_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -360px;-ms-high-contrast-adjust: none;}
img.line76_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -360px;-ms-high-contrast-adjust: none;}

img.line77_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -380px;-ms-high-contrast-adjust: none;}
img.line78_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -380px;-ms-high-contrast-adjust: none;}
img.line79_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -380px;-ms-high-contrast-adjust: none;}
img.line80_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -380px;-ms-high-contrast-adjust: none;}

img.line81_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -400px;-ms-high-contrast-adjust: none;}
img.line82_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -400px;-ms-high-contrast-adjust: none;}
img.line83_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -400px;-ms-high-contrast-adjust: none;}
img.line84_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -400px;-ms-high-contrast-adjust: none;}

img.line85_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -420px;-ms-high-contrast-adjust: none;}
img.line86_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -420px;-ms-high-contrast-adjust: none;}
img.line87_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -420px;-ms-high-contrast-adjust: none;}
img.line88_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -420px;-ms-high-contrast-adjust: none;}

img.line89_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -440px;-ms-high-contrast-adjust: none;}
img.line90_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -440px;-ms-high-contrast-adjust: none;}
img.line91_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -440px;-ms-high-contrast-adjust: none;}
img.line92_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -440px;-ms-high-contrast-adjust: none;}

img.line93_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -460px;-ms-high-contrast-adjust: none;}
img.line94_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -460px;-ms-high-contrast-adjust: none;}
img.line95_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -460px;-ms-high-contrast-adjust: none;}
img.line96_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -460px;-ms-high-contrast-adjust: none;}

img.line97_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -480px;-ms-high-contrast-adjust: none;}
img.line98_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -480px;-ms-high-contrast-adjust: none;}
img.line99_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -480px;-ms-high-contrast-adjust: none;}
img.line100_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -480px;-ms-high-contrast-adjust: none;}

img.line101_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -500px;-ms-high-contrast-adjust: none;}
img.line102_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -500px;-ms-high-contrast-adjust: none;}
img.line103_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -500px;-ms-high-contrast-adjust: none;}
img.line104_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -500px;-ms-high-contrast-adjust: none;}

img.line105_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -520px;-ms-high-contrast-adjust: none;}
img.line106_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -520px;-ms-high-contrast-adjust: none;}
img.line107_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -520px;-ms-high-contrast-adjust: none;}
img.line108_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -520px;-ms-high-contrast-adjust: none;}

img.line109_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -540px;-ms-high-contrast-adjust: none;}
img.line110_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -540px;background-color:black;-ms-high-contrast-adjust: none;}
img.line111_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -540px;background-color:black;-ms-high-contrast-adjust: none;}
img.line112_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -540px;background-color:black;-ms-high-contrast-adjust: none;}

img.line113_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -560px;background-color:black;-ms-high-contrast-adjust: none;}
img.line114_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -560px;background-color:black;-ms-high-contrast-adjust: none;}
img.line115_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -560px;background-color:black;-ms-high-contrast-adjust: none;}
img.line116_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1880px;background-color:black;-ms-high-contrast-adjust: none;}

img.line117_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -580px;background-color:black;-ms-high-contrast-adjust: none;}
img.line118_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -580px;-ms-high-contrast-adjust: none;}
img.line119_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -580px;-ms-high-contrast-adjust: none;}
img.line120_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -580px;-ms-high-contrast-adjust: none;}

img.line121_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -600px;-ms-high-contrast-adjust: none;}
img.line122_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -600px;-ms-high-contrast-adjust: none;}
img.line123_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -600px;-ms-high-contrast-adjust: none;}
img.line124_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -600px;background-color:black;-ms-high-contrast-adjust: none;}


img.line1_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -620px;-ms-high-contrast-adjust: none;}
img.line2_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -620px; background-color:black;-ms-high-contrast-adjust: none;}
img.line3_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -620px;background-color:black;-ms-high-contrast-adjust: none;}
img.line4_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -620px;background-color:black;-ms-high-contrast-adjust: none;}

img.line5_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -640px;background-color:black;-ms-high-contrast-adjust: none;}
img.line6_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -640px;background-color:black;-ms-high-contrast-adjust: none;}
img.line7_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -640px;background-color:black;-ms-high-contrast-adjust: none;}
img.line8_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -640px;background-color:black;-ms-high-contrast-adjust: none;}

img.line9_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -660px;background-color:black;-ms-high-contrast-adjust: none;}
img.line10_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -660px;background-color:black;-ms-high-contrast-adjust: none;}
img.line11_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -660px;background-color:black;-ms-high-contrast-adjust: none;}
img.line12_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -660px;background-color:black;-ms-high-contrast-adjust: none;}

img.line13_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -680px;background-color:black;-ms-high-contrast-adjust: none;}
img.line14_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -680px;background-color:black;-ms-high-contrast-adjust: none;}
img.line15_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -680px;background-color:black;-ms-high-contrast-adjust: none;}
img.line16_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -680px;background-color:black;-ms-high-contrast-adjust: none;}

img.line17_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -700px;background-color:black;-ms-high-contrast-adjust: none;}
img.line18_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -700px;background-color:black;-ms-high-contrast-adjust: none;}
img.line19_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -700px;background-color:black;-ms-high-contrast-adjust: none;}
img.line20_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -700px;background-color:black;-ms-high-contrast-adjust: none;}

img.line21_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -720px;background-color:black;-ms-high-contrast-adjust: none;}
img.line22_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -720px;background-color:black;-ms-high-contrast-adjust: none;}
img.line23_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -720px;background-color:black;-ms-high-contrast-adjust: none;}
img.line24_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -720px;background-color:black;-ms-high-contrast-adjust: none;}

img.line25_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -740px;background-color:black;-ms-high-contrast-adjust: none;}
img.line26_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -740px;background-color:black;-ms-high-contrast-adjust: none;}
img.line27_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -740px;background-color:black;-ms-high-contrast-adjust: none;}
img.line28_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -740px;background-color:black;-ms-high-contrast-adjust: none;}

img.line29_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -760px;background-color:black;-ms-high-contrast-adjust: none;}
img.line30_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -760px;background-color:black;-ms-high-contrast-adjust: none;}
img.line31_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -760px;background-color:black;-ms-high-contrast-adjust: none;}
img.line32_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -760px;background-color:black;-ms-high-contrast-adjust: none;}

img.line33_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -780px;background-color:black;-ms-high-contrast-adjust: none;}
img.line34_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -780px;background-color:black;-ms-high-contrast-adjust: none;}
img.line35_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -780px;background-color:black;-ms-high-contrast-adjust: none;}
img.line36_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -780px;background-color:black;-ms-high-contrast-adjust: none;}

img.line37_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -800px;background-color:black;-ms-high-contrast-adjust: none;}
img.line38_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -800px;background-color:black;-ms-high-contrast-adjust: none;}
img.line39_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -800px;background-color:black;-ms-high-contrast-adjust: none;}
img.line40_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -800px;background-color:black;-ms-high-contrast-adjust: none;}

img.line41_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -820px;background-color:black;-ms-high-contrast-adjust: none;}
img.line42_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -820px;background-color:black;-ms-high-contrast-adjust: none;}
img.line43_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -820px;background-color:black;-ms-high-contrast-adjust: none;}
img.line44_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -820px;background-color:black;-ms-high-contrast-adjust: none;}

img.line45_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -840px;background-color:black;-ms-high-contrast-adjust: none;}
img.line46_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -840px;background-color:black;-ms-high-contrast-adjust: none;}
img.line47_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -840px;background-color:black;-ms-high-contrast-adjust: none;}
img.line48_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -840px;background-color:black;-ms-high-contrast-adjust: none;}

img.line49_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -860px;background-color:black;-ms-high-contrast-adjust: none;}
img.line50_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -860px;background-color:black;-ms-high-contrast-adjust: none;}
img.line51_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -860px;background-color:black;-ms-high-contrast-adjust: none;}
img.line52_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -860px;background-color:black;-ms-high-contrast-adjust: none;}

img.line53_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -880px;background-color:black;-ms-high-contrast-adjust: none;}
img.line54_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -880px;background-color:black;-ms-high-contrast-adjust: none;}
img.line55_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -880px;background-color:black;-ms-high-contrast-adjust: none;}
img.line56_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -880px;background-color:black;-ms-high-contrast-adjust: none;}

img.line57_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -900px;background-color:black;-ms-high-contrast-adjust: none;}
img.line58_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -900px;background-color:black;-ms-high-contrast-adjust: none;}
img.line59_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -900px;background-color:black;-ms-high-contrast-adjust: none;}
img.line60_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -900px;background-color:black;-ms-high-contrast-adjust: none;}

img.line61_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -920px;background-color:black;-ms-high-contrast-adjust: none;}
img.line62_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -920px;background-color:black;-ms-high-contrast-adjust: none;}
img.line63_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -920px;-ms-high-contrast-adjust: none;}
img.line64_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -920px;-ms-high-contrast-adjust: none;}

img.line65_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -940px;-ms-high-contrast-adjust: none;}
img.line66_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -940px;-ms-high-contrast-adjust: none;}
img.line67_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -940px;-ms-high-contrast-adjust: none;}
img.line68_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -940px;-ms-high-contrast-adjust: none;}

img.line69_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -960px;-ms-high-contrast-adjust: none;}
img.line70_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -960px;-ms-high-contrast-adjust: none;}
img.line71_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -960px;-ms-high-contrast-adjust: none;}
img.line72_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -960px;-ms-high-contrast-adjust: none;}

img.line73_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -980px;-ms-high-contrast-adjust: none;}
img.line74_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -980px;-ms-high-contrast-adjust: none;}
img.line75_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -980px;-ms-high-contrast-adjust: none;}
img.line76_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -980px;-ms-high-contrast-adjust: none;}

img.line77_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1000px;-ms-high-contrast-adjust: none;}
img.line78_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1000px;-ms-high-contrast-adjust: none;}
img.line79_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1000px;-ms-high-contrast-adjust: none;}
img.line80_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1000px;-ms-high-contrast-adjust: none;}

img.line81_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1020px;-ms-high-contrast-adjust: none;}
img.line82_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1020px;-ms-high-contrast-adjust: none;}
img.line83_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1020px;-ms-high-contrast-adjust: none;}
img.line84_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1020px;-ms-high-contrast-adjust: none;}

img.line85_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1040px;-ms-high-contrast-adjust: none;}
img.line86_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1040px;-ms-high-contrast-adjust: none;}
img.line87_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1040px;-ms-high-contrast-adjust: none;}
img.line88_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1040px;-ms-high-contrast-adjust: none;}

img.line89_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1060px;-ms-high-contrast-adjust: none;}
img.line90_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1060px;-ms-high-contrast-adjust: none;}
img.line91_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1060px;-ms-high-contrast-adjust: none;}
img.line92_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1060px;-ms-high-contrast-adjust: none;}

img.line93_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1080px;-ms-high-contrast-adjust: none;}
img.line94_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1080px;-ms-high-contrast-adjust: none;}
img.line95_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1080px;-ms-high-contrast-adjust: none;}
img.line96_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1080px;-ms-high-contrast-adjust: none;}

img.line97_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1100px;-ms-high-contrast-adjust: none;}
img.line98_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1100px;-ms-high-contrast-adjust: none;}
img.line99_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1100px;-ms-high-contrast-adjust: none;}
img.line100_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1100px;-ms-high-contrast-adjust: none;}

img.line101_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1120px;-ms-high-contrast-adjust: none;}
img.line102_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1120px;-ms-high-contrast-adjust: none;}
img.line103_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1120px;-ms-high-contrast-adjust: none;}
img.line104_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1120px;-ms-high-contrast-adjust: none;}

img.line105_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1140px;-ms-high-contrast-adjust: none;}
img.line106_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1140px;-ms-high-contrast-adjust: none;}
img.line107_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1140px;-ms-high-contrast-adjust: none;}
img.line108_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1140px;-ms-high-contrast-adjust: none;}

img.line109_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1160px;-ms-high-contrast-adjust: none;}
img.line110_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1160px;background-color:black;-ms-high-contrast-adjust: none;}
img.line111_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1160px;background-color:black;-ms-high-contrast-adjust: none;}
img.line112_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1160px;background-color:black;-ms-high-contrast-adjust: none;}

img.line113_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1180px;background-color:black;-ms-high-contrast-adjust: none;}
img.line114_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1180px;background-color:black;-ms-high-contrast-adjust: none;}
img.line115_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1180px;background-color:black;-ms-high-contrast-adjust: none;}
img.line116_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -60px;background-color:black;-ms-high-contrast-adjust: none;}

img.line117_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1200px;background-color:black;-ms-high-contrast-adjust: none;}
img.line118_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1200px;-ms-high-contrast-adjust: none;}
img.line119_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1200px;-ms-high-contrast-adjust: none;}
img.line120_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1200px;-ms-high-contrast-adjust: none;}

img.line121_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1220px;-ms-high-contrast-adjust: none;}
img.line122_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1220px;-ms-high-contrast-adjust: none;}
img.line123_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1220px;-ms-high-contrast-adjust: none;}
img.line124_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1220px;background-color:black;-ms-high-contrast-adjust: none;}


img.line1_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1240px;-ms-high-contrast-adjust: none;}
img.line2_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1240px; background-color:black;-ms-high-contrast-adjust: none;}
img.line3_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1240px;background-color:black;-ms-high-contrast-adjust: none;}
img.line4_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1240px;background-color:black;-ms-high-contrast-adjust: none;}

img.line5_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1260px;background-color:black;-ms-high-contrast-adjust: none;}
img.line6_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1260px;background-color:black;-ms-high-contrast-adjust: none;}
img.line7_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1260px;background-color:black;-ms-high-contrast-adjust: none;}
img.line8_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1260px;background-color:black;-ms-high-contrast-adjust: none;}

img.line9_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1280px;background-color:black;-ms-high-contrast-adjust: none;}
img.line10_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1280px;background-color:black;-ms-high-contrast-adjust: none;}
img.line11_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1280px;background-color:black;-ms-high-contrast-adjust: none;}
img.line12_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1280px;background-color:black;-ms-high-contrast-adjust: none;}

img.line13_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1300px;background-color:black;-ms-high-contrast-adjust: none;}
img.line14_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1300px;background-color:black;-ms-high-contrast-adjust: none;}
img.line15_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1300px;background-color:black;-ms-high-contrast-adjust: none;}
img.line16_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1300px;background-color:black;-ms-high-contrast-adjust: none;}

img.line17_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1320px;background-color:black;-ms-high-contrast-adjust: none;;}
img.line18_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1320px;background-color:black;-ms-high-contrast-adjust: none;}
img.line19_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1320px;background-color:black;-ms-high-contrast-adjust: none;}
img.line20_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1320px;background-color:black;-ms-high-contrast-adjust: none;}

img.line21_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1340px;background-color:black;-ms-high-contrast-adjust: none;}
img.line22_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1340px;background-color:black;-ms-high-contrast-adjust: none;}
img.line23_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1340px;background-color:black;-ms-high-contrast-adjust: none;}
img.line24_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1340px;background-color:black;-ms-high-contrast-adjust: none;}

img.line25_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1360px;background-color:black;-ms-high-contrast-adjust: none;}
img.line26_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1360px;background-color:black;-ms-high-contrast-adjust: none;}
img.line27_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1360px;background-color:black;-ms-high-contrast-adjust: none;}
img.line28_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1360px;background-color:black;-ms-high-contrast-adjust: none;}

img.line29_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1380px;background-color:black;-ms-high-contrast-adjust: none;}
img.line30_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1380px;background-color:black;-ms-high-contrast-adjust: none;}
img.line31_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1380px;background-color:black;-ms-high-contrast-adjust: none;}
img.line32_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1380px;background-color:black;-ms-high-contrast-adjust: none;}

img.line33_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1400px;background-color:black;-ms-high-contrast-adjust: none;}
img.line34_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1400px;background-color:black;-ms-high-contrast-adjust: none;}
img.line35_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1400px;background-color:black;-ms-high-contrast-adjust: none;}
img.line36_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1400px;background-color:black;-ms-high-contrast-adjust: none;}

img.line37_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1420px;background-color:black;-ms-high-contrast-adjust: none;}
img.line38_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1420px;background-color:black;-ms-high-contrast-adjust: none;}
img.line39_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1420px;background-color:black;-ms-high-contrast-adjust: none;}
img.line40_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1420px;background-color:black;-ms-high-contrast-adjust: none;}

img.line41_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1440px;background-color:black;-ms-high-contrast-adjust: none;}
img.line42_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1440px;background-color:black;-ms-high-contrast-adjust: none;}
img.line43_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1440px;background-color:black;-ms-high-contrast-adjust: none;}
img.line44_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1440px;background-color:black;-ms-high-contrast-adjust: none;}

img.line45_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1460px;background-color:black;-ms-high-contrast-adjust: none;}
img.line46_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1460px;background-color:black;-ms-high-contrast-adjust: none;}
img.line47_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1460px;background-color:black;-ms-high-contrast-adjust: none;}
img.line48_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1460px;background-color:black;-ms-high-contrast-adjust: none;}

img.line49_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1480px;background-color:black;-ms-high-contrast-adjust: none;}
img.line50_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1480px;background-color:black;-ms-high-contrast-adjust: none;}
img.line51_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1480px;background-color:black;-ms-high-contrast-adjust: none;}
img.line52_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1480px;background-color:black;-ms-high-contrast-adjust: none;}

img.line53_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1500px;background-color:black;-ms-high-contrast-adjust: none;}
img.line54_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1500px;background-color:black;-ms-high-contrast-adjust: none;}
img.line55_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1500px;background-color:black;-ms-high-contrast-adjust: none;}
img.line56_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1500px;background-color:black;-ms-high-contrast-adjust: none;}

img.line57_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1520px;background-color:black;-ms-high-contrast-adjust: none;}
img.line58_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1520px;background-color:black;-ms-high-contrast-adjust: none;}
img.line59_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1520px;background-color:black;-ms-high-contrast-adjust: none;}
img.line60_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1520px;background-color:black;-ms-high-contrast-adjust: none;}

img.line61_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1540px;background-color:black;-ms-high-contrast-adjust: none;}
img.line62_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1540px;background-color:black;-ms-high-contrast-adjust: none;}
img.line63_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1540px;-ms-high-contrast-adjust: none;}
img.line64_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1540px;-ms-high-contrast-adjust: none;}

img.line65_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1560px;-ms-high-contrast-adjust: none;}
img.line66_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1560px;-ms-high-contrast-adjust: none;}
img.line67_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1560px;-ms-high-contrast-adjust: none;}
img.line68_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1560px;-ms-high-contrast-adjust: none;}

img.line69_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1580px;-ms-high-contrast-adjust: none;}
img.line70_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1580px;-ms-high-contrast-adjust: none;}
img.line71_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1580px;-ms-high-contrast-adjust: none;}
img.line72_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1580px;-ms-high-contrast-adjust: none;}

img.line73_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1600px;-ms-high-contrast-adjust: none;}
img.line74_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1600px;-ms-high-contrast-adjust: none;}
img.line75_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1600px;-ms-high-contrast-adjust: none;}
img.line76_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1600px;-ms-high-contrast-adjust: none;}

img.line77_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1620px;-ms-high-contrast-adjust: none;}
img.line78_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1620px;-ms-high-contrast-adjust: none;}
img.line79_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1620px;-ms-high-contrast-adjust: none;}
img.line80_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1620px;-ms-high-contrast-adjust: none;}

img.line81_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1640px;-ms-high-contrast-adjust: none;}
img.line82_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1640px;-ms-high-contrast-adjust: none;}
img.line83_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1640px;-ms-high-contrast-adjust: none;}
img.line84_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1640px;-ms-high-contrast-adjust: none;}

img.line85_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1660px;-ms-high-contrast-adjust: none;}
img.line86_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1660px;-ms-high-contrast-adjust: none;}
img.line87_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1660px;-ms-high-contrast-adjust: none;}
img.line88_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1660px;-ms-high-contrast-adjust: none;}

img.line89_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1680px;-ms-high-contrast-adjust: none;}
img.line90_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1680px;-ms-high-contrast-adjust: none;}
img.line91_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1680px;-ms-high-contrast-adjust: none;}
img.line92_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1680px;-ms-high-contrast-adjust: none;}

img.line93_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1700px;-ms-high-contrast-adjust: none;}
img.line94_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1700px;-ms-high-contrast-adjust: none;}
img.line95_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1700px;-ms-high-contrast-adjust: none;}
img.line96_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1700px;-ms-high-contrast-adjust: none;}

img.line97_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1720px;-ms-high-contrast-adjust: none;}
img.line98_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1720px;-ms-high-contrast-adjust: none;}
img.line99_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1720px;-ms-high-contrast-adjust: none;}
img.line100_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1720px;-ms-high-contrast-adjust: none;}

img.line101_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1740px;-ms-high-contrast-adjust: none;}
img.line102_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1740px;-ms-high-contrast-adjust: none;}
img.line103_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1740px;-ms-high-contrast-adjust: none;}
img.line104_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1740px;-ms-high-contrast-adjust: none;}

img.line105_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1760px;-ms-high-contrast-adjust: none;}
img.line106_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1760px;-ms-high-contrast-adjust: none;}
img.line107_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1760px;-ms-high-contrast-adjust: none;}
img.line108_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1760px;-ms-high-contrast-adjust: none;}

img.line109_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1780px;-ms-high-contrast-adjust: none;}
img.line110_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1780px;background-color:black;-ms-high-contrast-adjust: none;}
img.line111_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1780px;background-color:black;-ms-high-contrast-adjust: none;}
img.line112_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1780px;background-color:black;-ms-high-contrast-adjust: none;}

img.line113_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1800px;background-color:black;-ms-high-contrast-adjust: none;}
img.line114_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1800px;background-color:black;-ms-high-contrast-adjust: none;}
img.line115_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1800px;background-color:black;-ms-high-contrast-adjust: none;}
img.line116_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -120px;background-color:black;-ms-high-contrast-adjust: none;}

img.line117_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1820px;background-color:black;-ms-high-contrast-adjust: none;}
img.line118_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1820px;-ms-high-contrast-adjust: none;}
img.line119_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1820px;-ms-high-contrast-adjust: none;}
img.line120_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1820px;-ms-high-contrast-adjust: none;}

img.line121_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1840px;-ms-high-contrast-adjust: none;}
img.line122_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1840px;-ms-high-contrast-adjust: none;}
img.line123_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1840px;-ms-high-contrast-adjust: none;}
img.line124_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1840px;background-color:black;-ms-high-contrast-adjust: none;}


/*for old ParkMap line styles*/
/*1pixel*/
img.line219_1{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1860px;-ms-high-contrast-adjust: none;}
img.line220_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1860px;-ms-high-contrast-adjust: none;}
img.line221_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1860px;-ms-high-contrast-adjust: none;}
img.line222_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1860px;-ms-high-contrast-adjust: none;}

img.line223_1{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1880px;-ms-high-contrast-adjust: none;}
img.line224_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -600px;-ms-high-contrast-adjust: none;}
img.line225_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1880px;-ms-high-contrast-adjust: none;}
img.line226_1 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1880px;-ms-high-contrast-adjust: none;}
img.line227_1{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1900px;-ms-high-contrast-adjust: none;}

/*2pixels*/
img.line219_2{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -60px;-ms-high-contrast-adjust: none;}
img.line220_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -60px;-ms-high-contrast-adjust: none;}
img.line221_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -60px;-ms-high-contrast-adjust: none;}
img.line222_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -60px;-ms-high-contrast-adjust: none;}

img.line223_2{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -60px;-ms-high-contrast-adjust: none;}
img.line224_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -620px;-ms-high-contrast-adjust: none;}
img.line225_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -60px;-ms-high-contrast-adjust: none;}
img.line226_2 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -60px;-ms-high-contrast-adjust: none;}
img.line227_2{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -60px;-ms-high-contrast-adjust: none;}

/*3pixels*/
img.line219_3{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -1860px;-ms-high-contrast-adjust: none;}
img.line220_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1860px;-ms-high-contrast-adjust: none;}
img.line221_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -1860px;-ms-high-contrast-adjust: none;}
img.line222_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -1860px;-ms-high-contrast-adjust: none;}

img.line223_3{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -120px;-ms-high-contrast-adjust: none;}
img.line224_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -35px -1240px;-ms-high-contrast-adjust: none;}
img.line225_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -70px -120px;-ms-high-contrast-adjust: none;}
img.line226_3 {width:35px;height:20px; background: url(/img/symbols/lines.png) -105px -120px;-ms-high-contrast-adjust: none;}
img.line227_3{width:35px;height:20px; background: url(/img/symbols/lines.png) 0 -120px;-ms-high-contrast-adjust: none;}


/*REGION STYLES*/
img.region1 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 0;-ms-high-contrast-adjust: none;}
img.region2 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px 0; background-color:black;-ms-high-contrast-adjust: none;}
img.region3 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px 0;background-color:black;-ms-high-contrast-adjust: none;}
img.region4 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px 0;background-color:black;-ms-high-contrast-adjust: none;}

img.region5 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -20px;background-color:black;-ms-high-contrast-adjust: none;}
img.region6 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -20px;background-color:black;-ms-high-contrast-adjust: none;}
img.region7 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -20px;background-color:black;-ms-high-contrast-adjust: none;}
img.region8 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -20px;background-color:black;-ms-high-contrast-adjust: none;}

img.region12 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -40px;background-color:black;-ms-high-contrast-adjust: none;}
img.region13 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -40px;background-color:black;-ms-high-contrast-adjust: none;}
img.region14 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -40px;background-color:black;-ms-high-contrast-adjust: none;}
img.region15 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -40px;background-color:black;-ms-high-contrast-adjust: none;}

img.region16 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -60px;background-color:black;-ms-high-contrast-adjust: none;}
img.region17 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -60px;background-color:black;-ms-high-contrast-adjust: none;}
img.region18 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -60px;background-color:black;-ms-high-contrast-adjust: none;}
img.region19 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -60px;background-color:black;-ms-high-contrast-adjust: none;}

img.region20 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -80px;background-color:black;-ms-high-contrast-adjust: none;}
img.region21 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -80px;background-color:black;-ms-high-contrast-adjust: none;}
img.region22 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -80px;background-color:black;-ms-high-contrast-adjust: none;}
img.region23 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -80px;background-color:black;-ms-high-contrast-adjust: none;}

img.region24 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -100px;background-color:black;-ms-high-contrast-adjust: none;}
img.region25 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -100px;background-color:black;-ms-high-contrast-adjust: none;}
img.region26 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -100px;background-color:black;-ms-high-contrast-adjust: none;}
img.region27 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -100px;background-color:black;-ms-high-contrast-adjust: none;}

img.region28 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -120px;background-color:black;-ms-high-contrast-adjust: none;}
img.region29 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -120px;background-color:black;-ms-high-contrast-adjust: none;}
img.region30 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -120px;background-color:black;-ms-high-contrast-adjust: none;}
img.region31 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -120px;background-color:black;-ms-high-contrast-adjust: none;}

img.region32 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -140px;background-color:black;-ms-high-contrast-adjust: none;}
img.region33 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -140px;background-color:black;-ms-high-contrast-adjust: none;}
img.region34 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -140px;background-color:black;-ms-high-contrast-adjust: none;}
img.region35 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -140px;background-color:black;-ms-high-contrast-adjust: none;}

img.region36 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -160px;background-color:black;-ms-high-contrast-adjust: none;}
img.region37 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -160px;background-color:black;-ms-high-contrast-adjust: none;}
img.region38 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -160px;background-color:black;-ms-high-contrast-adjust: none;}
img.region39 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -160px;background-color:black;-ms-high-contrast-adjust: none;}

img.region40 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -180px;background-color:black;-ms-high-contrast-adjust: none;}
img.region41 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -180px;background-color:black;-ms-high-contrast-adjust: none;}
img.region42 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -180px;background-color:black;-ms-high-contrast-adjust: none;}
img.region43{width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -180px;background-color:black;-ms-high-contrast-adjust: none;}

img.region44 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -200px;background-color:black;-ms-high-contrast-adjust: none;}
img.region45 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -200px;background-color:black;-ms-high-contrast-adjust: none;}
img.region46 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -200px;background-color:black;-ms-high-contrast-adjust: none;}
img.region47 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -200px;background-color:black;-ms-high-contrast-adjust: none;}

img.region48 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -220px;background-color:black;-ms-high-contrast-adjust: none;}
img.region49 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -220px;background-color:black;-ms-high-contrast-adjust: none;}
img.region50 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -220px;background-color:black;-ms-high-contrast-adjust: none;}
img.region51 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -220px;background-color:black;-ms-high-contrast-adjust: none;}

img.region52 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -240px;background-color:black;-ms-high-contrast-adjust: none;}
img.region53 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -240px;background-color:black;-ms-high-contrast-adjust: none;}
img.region54 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -240px;background-color:black;-ms-high-contrast-adjust: none;}
img.region55 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -240px;background-color:black;-ms-high-contrast-adjust: none;}

img.region56 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -260px;background-color:black;-ms-high-contrast-adjust: none;}
img.region57 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -260px;background-color:black;-ms-high-contrast-adjust: none;}
img.region58 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -260px;background-color:black;-ms-high-contrast-adjust: none;}
img.region59 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -260px;background-color:black;-ms-high-contrast-adjust: none;}

img.region60 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -280px;background-color:black;-ms-high-contrast-adjust: none;}
img.region61 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -280px;background-color:black;-ms-high-contrast-adjust: none;}
img.region62 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -280px;background-color:black;-ms-high-contrast-adjust: none;}
img.region63 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -280px;background-color:black;-ms-high-contrast-adjust: none;}

img.region64 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -300px;background-color:black;-ms-high-contrast-adjust: none;}
img.region65 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -300px;background-color:black;-ms-high-contrast-adjust: none;}
img.region66 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -300px;-ms-high-contrast-adjust: none;}
img.region67 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -300px;-ms-high-contrast-adjust: none;}

img.region68 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -320px;-ms-high-contrast-adjust: none;}
img.region69{width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -320px;-ms-high-contrast-adjust: none;}
img.region70 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -320px;-ms-high-contrast-adjust: none;}
img.region71 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -320px;-ms-high-contrast-adjust: none;}

img.region72 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -340px;-ms-high-contrast-adjust: none;}
img.region73 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -340px;-ms-high-contrast-adjust: none;}
img.region74 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -340px;-ms-high-contrast-adjust: none;}
img.region75 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -340px;-ms-high-contrast-adjust: none;}

img.region76 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -360px;-ms-high-contrast-adjust: none;}
img.region77 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -360px;-ms-high-contrast-adjust: none;}
img.region78 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -360px;-ms-high-contrast-adjust: none;}
img.region79 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -360px;-ms-high-contrast-adjust: none;}

img.region80 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -380px;-ms-high-contrast-adjust: none;}
img.region81 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -380px;-ms-high-contrast-adjust: none;}
img.region82 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -380px;-ms-high-contrast-adjust: none;}
img.region83 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -380px;-ms-high-contrast-adjust: none;}

img.region84{width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -400px;-ms-high-contrast-adjust: none;}
img.region85 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -400px;-ms-high-contrast-adjust: none;}
img.region86 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -400px;-ms-high-contrast-adjust: none;}
img.region87 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -400px;-ms-high-contrast-adjust: none;}

img.region88 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -420px;-ms-high-contrast-adjust: none;}
img.region89 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -420px;-ms-high-contrast-adjust: none;}
img.region90 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -420px;-ms-high-contrast-adjust: none;}
img.region91 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -420px;-ms-high-contrast-adjust: none;}

img.region92 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -440px;-ms-high-contrast-adjust: none;}
img.region93 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -440px;-ms-high-contrast-adjust: none;}
img.region94 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -440px;-ms-high-contrast-adjust: none;}
img.region95 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -440px;-ms-high-contrast-adjust: none;}

img.region96 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -460px;-ms-high-contrast-adjust: none;}
img.region97 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -460px;-ms-high-contrast-adjust: none;}
img.region98 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -460px;-ms-high-contrast-adjust: none;}
img.region99 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -460px;-ms-high-contrast-adjust: none;}

img.region100 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -480px;-ms-high-contrast-adjust: none;}
img.region101 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -480px;-ms-high-contrast-adjust: none;}
img.region102 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -480px;-ms-high-contrast-adjust: none;}
img.region103 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -480px;-ms-high-contrast-adjust: none;}

img.region104 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -500px;-ms-high-contrast-adjust: none;}
img.region105 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -500px;-ms-high-contrast-adjust: none;}
img.region106 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -500px;-ms-high-contrast-adjust: none;}
img.region107 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -500px;-ms-high-contrast-adjust: none;}

img.region108 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -520px;-ms-high-contrast-adjust: none;}
img.region109 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -520px;-ms-high-contrast-adjust: none;}
img.region110 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -520px;-ms-high-contrast-adjust: none;}
img.region111 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -520px;-ms-high-contrast-adjust: none;}

img.region112 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -540px;-ms-high-contrast-adjust: none;}
img.region113 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -540px;background-color:black;-ms-high-contrast-adjust: none;}
img.region114 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -540px;background-color:black;-ms-high-contrast-adjust: none;}
img.region115 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -540px;background-color:black;-ms-high-contrast-adjust: none;}

img.region116 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -560px;background-color:black;-ms-high-contrast-adjust: none;}
img.region117 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -560px;background-color:black;-ms-high-contrast-adjust: none;}
img.region118 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -560px;background-color:black;-ms-high-contrast-adjust: none;}
img.region119 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -560px;background-color:black;-ms-high-contrast-adjust: none;}

img.region120 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -580px;background-color:black;-ms-high-contrast-adjust: none;}
img.region121 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -580px;-ms-high-contrast-adjust: none;}
img.region122 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -580px;-ms-high-contrast-adjust: none;}
img.region123 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -580px;-ms-high-contrast-adjust: none;}

img.region124 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -600px;-ms-high-contrast-adjust: none;}
img.region125 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -600px;-ms-high-contrast-adjust: none;}
img.region126 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -600px;-ms-high-contrast-adjust: none;}
img.region127 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -600px;background-color:black;-ms-high-contrast-adjust: none;}

img.region128 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -620px;-ms-high-contrast-adjust: none;}
img.region129 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -620px; background-color:black;-ms-high-contrast-adjust: none;}
img.region130 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -620px;background-color:black;-ms-high-contrast-adjust: none;}
img.region131 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -620px;background-color:black;-ms-high-contrast-adjust: none;}

img.region132 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -640px;background-color:black;-ms-high-contrast-adjust: none;}
img.region133 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -640px;background-color:black;-ms-high-contrast-adjust: none;}
img.region134 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -640px;background-color:black;-ms-high-contrast-adjust: none;}
img.region135 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -640px;background-color:black;-ms-high-contrast-adjust: none;}

img.region136 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -660px;background-color:black;-ms-high-contrast-adjust: none;}
img.region137 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -660px;background-color:black;-ms-high-contrast-adjust: none;}
img.region138 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -660px;background-color:black;-ms-high-contrast-adjust: none;}
img.region139 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -660px;background-color:black;-ms-high-contrast-adjust: none;}

img.region140 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -680px;background-color:black;-ms-high-contrast-adjust: none;}
img.region141 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -680px;background-color:black;-ms-high-contrast-adjust: none;}
img.region142 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -680px;background-color:black;-ms-high-contrast-adjust: none;}
img.region143 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -680px;background-color:black;-ms-high-contrast-adjust: none;}

img.region144 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -700px;background-color:black;-ms-high-contrast-adjust: none;}
img.region145 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -700px;background-color:black;-ms-high-contrast-adjust: none;}
img.region146 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -700px;background-color:black;-ms-high-contrast-adjust: none;}
img.region147 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -700px;background-color:black;-ms-high-contrast-adjust: none;}

img.region148 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -720px;background-color:black;-ms-high-contrast-adjust: none;}
img.region149 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -720px;background-color:black;-ms-high-contrast-adjust: none;}
img.region150 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -720px;background-color:black;-ms-high-contrast-adjust: none;}
img.region151 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -720px;background-color:black;-ms-high-contrast-adjust: none;}

img.region152 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -740px;background-color:black;-ms-high-contrast-adjust: none;}
img.region153 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -740px;background-color:black;-ms-high-contrast-adjust: none;}
img.region154 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -740px;background-color:black;-ms-high-contrast-adjust: none;}
img.region155 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -740px;background-color:black;-ms-high-contrast-adjust: none;}

img.region156 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -760px;background-color:black;-ms-high-contrast-adjust: none;}
img.region157 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -760px;background-color:black;-ms-high-contrast-adjust: none;}
img.region158 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -760px;background-color:black;-ms-high-contrast-adjust: none;}
img.region159 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -760px;background-color:black;-ms-high-contrast-adjust: none;}

img.region160 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -780px;background-color:black;-ms-high-contrast-adjust: none;}
img.region161 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -780px;background-color:black;-ms-high-contrast-adjust: none;}
img.region162 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -780px;background-color:black;-ms-high-contrast-adjust: none;}
img.region163 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -780px;background-color:black;-ms-high-contrast-adjust: none;}

img.region164 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -800px;background-color:black;-ms-high-contrast-adjust: none;}
img.region165 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -800px;background-color:black;-ms-high-contrast-adjust: none;}
img.region166 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -800px;background-color:black;-ms-high-contrast-adjust: none;}
img.region167 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -800px;background-color:black;-ms-high-contrast-adjust: none;}

img.region168 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -820px;background-color:black;-ms-high-contrast-adjust: none;}
img.region169 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -820px;background-color:black;-ms-high-contrast-adjust: none;}
img.region170 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -820px;background-color:black;-ms-high-contrast-adjust: none;}
img.region171 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -820px;background-color:black;-ms-high-contrast-adjust: none;}

img.region172 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -840px;background-color:black;-ms-high-contrast-adjust: none;}
img.region173 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -840px;background-color:black;-ms-high-contrast-adjust: none;}
img.region174 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -840px;background-color:black;-ms-high-contrast-adjust: none;}
img.region175 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -840px;background-color:black;-ms-high-contrast-adjust: none;}

img.region176 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -860px;background-color:black;-ms-high-contrast-adjust: none;}
img.region177 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -860px;background-color:black;-ms-high-contrast-adjust: none;}
img.region178 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -860px;background-color:black;-ms-high-contrast-adjust: none;}
img.region179 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -860px;background-color:black;-ms-high-contrast-adjust: none;}

img.region180 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -880px;background-color:black;-ms-high-contrast-adjust: none;}
img.region181 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -880px;background-color:black;-ms-high-contrast-adjust: none;}
img.region182 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -880px;background-color:black;-ms-high-contrast-adjust: none;}
img.region183 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -880px;background-color:black;-ms-high-contrast-adjust: none;}

img.region184 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -900px;background-color:black;-ms-high-contrast-adjust: none;}
img.region185 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -900px;background-color:black;-ms-high-contrast-adjust: none;}
img.region186 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -900px;background-color:black;-ms-high-contrast-adjust: none;}
img.region187 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -900px;background-color:black;-ms-high-contrast-adjust: none;}

img.region188 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -920px;background-color:black;-ms-high-contrast-adjust: none;}
img.region189 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -920px;background-color:black;-ms-high-contrast-adjust: none;}
img.region190 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -920px;-ms-high-contrast-adjust: none;}
img.region191 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -920px;-ms-high-contrast-adjust: none;}

img.region192 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -940px;-ms-high-contrast-adjust: none;}
img.region193 {width:35px;height:20px; background: url(/img/symbols/regions.png) -35px -940px;-ms-high-contrast-adjust: none;}
img.region194 {width:35px;height:20px; background: url(/img/symbols/regions.png) -70px -940px;-ms-high-contrast-adjust: none;}
img.region195 {width:35px;height:20px; background: url(/img/symbols/regions.png) -105px -940px;-ms-high-contrast-adjust: none;}

img.region196 {width:35px;height:20px; background: url(/img/symbols/regions.png) 0 -960px;-ms-high-contrast-adjust: none;}
