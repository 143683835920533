/* popup and info */
//IE 11 Fallback and mobile theme
#popup-closer {
  background: black;
  color: white;
}
#popup-closer:hover {
  color: white;
}

#popup-closer {
  color: white;
}
// END IF IE Fallback
// THEME COLORS
.close {
  opacity: 1 !important;
}
@media (min-width: 768px) {
  #popup-closer {
    background: var(--back-color);
    color: var(--font-color);
  }
  #popup-closer:hover {
    color: var(--font-highlight);
  }

  #popup-closer {
    color: var(--font-color);
  }
}

// END OF THEME COLOURS
#popup-closer {
  padding: 4px;
}
#popup-closer:hover {
  text-decoration: none
}

.infobutt {
    display: block;
    padding: 4px !important;
    width: 98% !important;
    margin: 10px 0 5px !important
  }

  #popup {
    z-index: 20;
    background-color: #fff;
    cursor: default !important;
  }

  #popup button {
    cursor: pointer
  }

  #popup ul label,
  #mobpopup ul label {
    cursor: pointer
  }

  #popup .edit-action,
  #mobpopup .edit-action {
    width: auto;
    padding: 0 7px
  }

  #popup .myview,
  #mobpopup .myview {
    margin: 4px auto
  }

  #popup .myview::before,
  #mobpopup .myview::before {
    margin-right: 5px
  }

  #popup .delete,
  #popup .save,
  #popup .editbtn,
  #mobpopup .delete,
  #mobpopup .save,
  #mobpopup .editbtn {
    cursor: pointer;
    width: 50%;
  }

  time {
    white-space: nowrap
  }

  .ol-pop,
  .ol-popup,
  .hoverPopup {
    position: absolute;
    background-color: #fff;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    padding: 15px;
    padding-top: 5px;
    padding-right: 20px;
    border-radius: .25rem;
    border: 1px solid #ccc;
    bottom: 12px;
    left: -50px;
    min-width: 365px
  }

  .pluspop {
    min-width: 500px;
  }

  .pluspop #popup-content div {
    max-width: 500px;
  }

  .ol-pop:after,
  .ol-pop:before,
  .hoverPop:after,
  .hoverPop:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
  }

  .ol-pop:after,
  .hoverPop:after {
    border-top-color: #fff;
    border-width: 10px;
    left: 48px;
    margin-left: -10px
  }

  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 4px;
    right: 8px
  }

  .ol-poprot,
  .hoverPoprot {
    background-color: #fff
  }

  .ol-poprot::after,
  .ol-poprot::before,
  .hoverPoprot:after,
  .hoverPoprot:before {
    position: absolute;
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
    border-top-color: #fff;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
  }


  #popup-content {
    overflow-y: auto;
    font-size: .95rem;
    margin-right: 5px;
  }

  #popup-content div {
    max-width: 350px
  }

  #popup-content a,
  #popup-content button {
    width: 100%;
    overflow: hidden
  }

  #popup-content img {
    max-width: 45%
  }

  #mobpopupbody button {
    overflow: hidden
  }

  #consultationdialog {
    overflow-y: auto;
  }

  #info {
    position: absolute;
    width: 1px;
    height: 1px
  }

  .hoverPopup {
    min-width: 250px;
    z-index: 10
  }

  .hoverPopup p {
    margin: 0 auto
  }

  .infotitle {
    font-weight: bold
  }

  .infotab {
    display: inline-block;
    width: 100%
  }

  .custflotitle {
    float: right;
    max-width: 50%;
    text-align: right
  }

  .spacer {
    height: 15px
  }

  .infotitle_norm {
    font-weight: normal
  }

  .doclist,
  #itemlist {
    padding: 0 10px 0
  }

  .doclist li,
  #itemlist li {
    cursor: pointer;
    padding: 5px;
    border: 1px solid #868e96;
    border-radius: .25rem;
    margin-bottom: 2px
  }

  @media(min-width: 318px) {
    #itembutton {
        width: 98%;
        margin: 10px 0 0;
        max-width: 760px;
        height: auto;
        padding: 6px;
        white-space: normal;
        font-size: 1rem
    }
}

@media(min-width: 768px) {
    #itembutton {
        width: 100%;
        max-width: 310px
    }
}

.itmbtnlist {
    position: relative;
    left: -4px;
    background: none
}

#itembutton .fa-caret-right {
    margin-top: 2px
}

#itembutton .fa-caret-down {
    margin-right: 2px;
    margin-top: 2px
}

#popup-content .itembutton_am {
    margin-bottom: 5px
}
@media(min-width: 768px) {
  #popup-content .am_plus {
      max-width: 445px
  }
}

  .pcTitle {
    display: block;
    width: 100%
  }

  .invdoclist {
    padding: 5px 10px;
    margin-bottom: .5rem
  }

  .invdoclist li {
    cursor: pointer;
    padding: 5px;
    border: 1px solid #868e96;
    border-radius: .25rem;
    margin-bottom: 2px
  }

  .estab,
  .tvptab {
    text-align: center
  }

  .estableft,
  .tvptableft {
    text-align: left
  }

  .estab th,
  .estab td,
  .estabacc th,
  .coltabacc th,
  .tvptab th,
  .tvptabacc th {
    padding: 0 5px
  }

  .estab td {
    max-width: 130px
  }

  .coltabacc th,
  .estabacc td,
  .tvptabacc td,
  .coltabacc td {
    text-align: center
  }

  .coltabacc {
    border: 1px solid #000;
    border-collapse: collapse;
    margin: 4px 2px
  }

  .back {
    background: rgba(239, 239, 239, .8)
  }
 .lalink {
    color: blue;
    font-weight: bold;
 }

  .coltop {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-collapse: collapse
  }

  .colbot th {
    border-bottom: 1px solid #000;
    border-collapse: collapse
  }

  .collef {
    border-left: 1px solid #000
  }

  .exemtitle {
    display: block;
    margin-bottom: 5px
  }

  .exCodeBtn {
    width: auto !important;
    height: auto !important;
    padding: 1px 4px;
    margin: 0 2px
  }

  .tExempDiv {
    display: inline-block;
    margin-bottom: 5px
  }

  .excode {
    padding: 1px 2px;
    margin: 0 2px;
    border: 1px solid #868e96;
    border-radius: .25rem;
    cursor: default
  }

  .btnlistmain {
    margin: 5px 2px
  }

  .deletediv {
    text-align: center
  }

  .deletediv .editbtn {
    cursor: pointer;
    width: 70px;
    margin: 0 5px
  }

  .editdiv {
    display: none;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: .25rem;
    z-index: 900
  }

  .editdiv textarea {
    width: 100%;
    border-radius: .25rem
  }

  .actiondiv {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: .25rem
  }

  .togle {
    padding-inline-start: 0px;
  }

  fieldset.groupbox-border {
    border: 1px solid #ccc !important;
    border-radius: 0.25rem;
    padding: 0 1em 1em 1em !important;
    margin-bottom: 1em !important;
    }

    legend.groupbox-border {
        font-size: 1em !important;
        font-weight: bold !important;
        text-align: left !important;
        width:auto;
        padding:0px 10px 0px 10px;
        border-bottom:none;
    }

    .fralabel {
        margin-left: 5px;
    }

    .fraimg {
        margin-top: 10px;
        margin-bottom: 10px;
    }
