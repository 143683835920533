
#toolbar {
    position:fixed;
    top: 5px;
    left: 5px;
    max-width: 950px;
    z-index: 900
}

#app{
    position: relative;
    min-height: 100vh;
}
.footer {
    left: 0;
    bottom: 0;
    width: 100%;
    height:30px;
}

#mapped_features {
    display:none;
}
.mobile-maptoolbtn {
    border: 1px solid white;
}
@media(max-width: 767px) {
    #customerlogo {
        display: none;
    }
    .navbar-brand {
        display: none;
    }
    .maptoolbtn {
        display: none;
    }
    .mobile-maptoolbtn {
        display: block;
    }
}

@media(min-width: 768px) {
    #customerlogo {
        display: block;
    }
    .navbar-brand {
        display: block;
    }
    .maptoolbtn {
        display: block;
    }
    .mobile-maptoolbtn {
        display: none;
    }
}

.streetname {
    list-style: none;
}

#twdata {
    display: none;
}

.psa-navbar {
    justify-content: left !important;
}

 .section {
     width: 100%;
 }

 #fileToUpload {
     display: none;
 }

 .disc_list {
     list-style-type: disc;
 }

 .buttonicon {
     padding-right: 5px;
 }

 .maptoolbar {
     padding: 3px 3px 3px 0px;
 }

 .previewZoomstack {
    width:100px;
    height:100px;
    border-radius: 0.25rem;
 }

 .themerow {
    //border-bottom: 1px solid #dee2e6;
    text-align:center;
    margin-top: 1rem;
 }

.ZoomstackDark {
    background-image: url('/img/zoomstack/ZoomstackDark.png');
}

.ZoomstackLight {
    background-image: url('/img/zoomstack/ZoomstackLight.png');
}

.ZoomstackRoad {
    background-image: url('/img/zoomstack/ZoomstackRoad.png');
}

.ZoomstackOutdoor {
    background-image: url('/img/zoomstack/ZoomstackOutdoor.png');
}

.ZoomstackDeuteranopia {
    background-image: url('/img/zoomstack/ZoomstackDeuteranopia.png');
}

.ZoomstackTritanopia {
    background-image: url('/img/zoomstack/ZoomstackTritanopia.png');
}

.themebtn {
    width: 250px;
    padding: 0px;
    border-radius: 10px;
    background-color: var(--back-color);
    color: var(--font-color);
}

.previewZoomstack {
    width:auto;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 0.5rem;
}

.selectedZoomstack {
    //border: solid 4px red;
    box-shadow: 0px 0px 20px black !important;
    background-color: var(--back-highlight);
    color: var(--font-highlight);
    transform: scale(1.05);
}

.themeRadio {
    padding-left: 5px;
}

/*
 * Theme Selector
 */
 #mapthemeDiv .themerow{
    border: 0px;
 }

.themeselector {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.themeselector .form-check-inline {
    margin: 0px;
}

.themeselector input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.themeselector label {
    display: block;
    text-align: center;
    width: 250px;
    background-color: #fff;
    border: 2.5px solid var(--border-color);
    color: var(--border-color);
    cursor: pointer;
}

.themeselector input[type='radio']:checked+label {
    background-color: var(--back-highlight);
    border-color: var(--back-color);
    color: var(--font-highlight);
}

.themeRadio-l {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-right: 0px !important;
}

.themeRadio-r {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-left: 0px !important;
}

.themeselector input[type='radio']:checked+.themeRadio-l {
    border-right: 2.5px solid var(--back-color) !important;
}
.themeselector input[type='radio']:checked+.themeRadio-r {
    border-left: 2.5px solid var(--back-color) !important;
}

/*
 * End theme selector
 */

.themerow {
    padding-bottom: 10px;
}

.tw-vertical-bar {
    border-left: 2px solid var(--font-color);
    max-height: 25px;
    -webkit-transform: translateY(30%);
    -ms-transform: translateY(30%);
    transform: translateY(30%);
}

.lang-flag {
    height: 15px;
}

.input-group-addon {
    padding: 10px !important;
}

.backgroundImageContainer {
    position: fixed;
}

.backgroundImageContainer > img {
    width: 100vw;
    opacity: 0.3;
}

.modal-lg, .modal-xl {
    max-width: 900px;
}

#find {
    height: calc(1.6em + 0.75rem + 3px) !important;
}

#schemesearch {
    margin-bottom: 0rem !important;
}

#schemeresultscontainer {
    margin-bottom: 1rem !important;
}

#schemeresults {
    display: none;
    background-color: white;
    border: 2px solid grey;
    border-radius: 0.2rem;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    padding-left:0px;
  }
  #schemeresults li {
    border-bottom: 1px solid silver;
  }
  .scheme-results li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  #schemeresults li:hover {background-color: #ddd;}
  .scheme-key-sel {background-color: #ddd;}


.amheaddisclaimer {
    height: 75px;
    width: 350px;
    font-size: .8rem;
    padding-left: 10px;
    font-weight: 700!important;
    display: none;
}

.amheaddisclaimer p{
    margin: 0;
    padding: 0;
}

.amheadwarn {
    color: red;
    background-color: white;
    border: 2px solid white;
    border-radius: 0.2rem;
}

@media(min-width: 768px) {
    .amheaddisclaimer{
        display:block;
    }
}

.badge {
    top: -6px;
    left: 16px;
}

.notification.unread {
    font-weight: bold;
}

.notification-row {
    //width: max-content;
    flex-wrap: nowrap !important;
    align-items: center;
}

@media (max-width: 992px) {
    #notifications {
        display: none;
    }
}
