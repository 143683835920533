/* Consultation and forms */
input:valid {
    border: 1px solid green;
   }
   input:invalid {
    border: 1px solid red;
   }
  
  .accsform label,
  #commentForm label {
      display: block !important;
      margin: 10px 0 0
  }
  
  .accsform textarea,
  #commentForm textarea {
      width: 100%
  }
  
  #ccomtype {
      margin-bottom: 5px
  }
  
  #accsformsuccess h4,
  #accsformerror h4,
  #commentMsg h4 {
      text-align: center
  }
  
  @media(min-width: 768px) {
      .split {
          width: 50%;
          min-height: 73px;
          height: auto;
          max-height: 90px;
          float: left;
          margin: 0
      }
  }
  
  @media(min-width: 768px) {
      #ccommentlabel,
      #addresslabel {
          clear: left;
          padding-top: 10px
      }
  }
  
  @media(min-width: 768px) {
      #cscheme,
      #ctitle,
      #cemail,
      #postcode {
          width: 97%
      }
  }
  
  #commentSubmit,
  #submit {
      width: 100%;
      height: 38px;
      margin: 0;
      padding: 0 10px
  }
  
  #commentSubmit {
      margin-top: 10px
  }
  
.emptyComm,
.sentOk,
.photoErr,
.sentErr {
  display: none;
  color: red
}

.sentOk {
  color: #000
}
